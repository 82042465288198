:root {
  --text: #f8f9f6;
  --background: #121212;
  --primary: #6a0d91;
  --secondary: #4b0082;
  --accent: #9b59b6;
}

/* General styles */
body {
  margin: 0;
  font-family: Arial, "Roboto";
  background-color: var(--background);
  color: var(--text);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* App container */
#root {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex-grow: 1;
}

/* Navbar */
.navbar {
  position: fixed;
  display: flex;
  width: calc(100% - 60px);
  z-index: 1000;
  background-color: var(--background);
  color: var(--text);
  top: 0;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  font-size: 1.7rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 10px;
  margin-left: auto;
  align-items: center;
  margin-right: 15px;
}

.nav-links a {
  color: var(--text);
  text-decoration: none;
  padding: 10px;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: var(--accent);
}

.social-media-icons {
  display: flex;
  gap: 12px;
  align-items: center;
}

.social-media-icons a {
  color: var(--text);
  font-size: 24px;
  transition: color 0.3s ease;
  padding: 10px;
}

.social-media-icons a:hover {
  color: var(--accent);
}

/* Main content */
.content {
  margin-top: 70px;
  padding: 20px;
  text-align: center;
  flex-grow: 1;
}

/* Photo sections */
.embla__play {
  padding: 5px 10px;
  border: 3px solid var(--primary);
  background-color: var(--background);
  color: var(--text);
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  font-size: 1.2rem;
}

.embla h2 {
  margin-right: 10px;
}

.embla h2,
.embla__play {
  display: inline-block;
  vertical-align: middle;
}

.embla__play:hover {
  background-color: var(--accent);
}

.embla {
  margin-top: 20px;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: 0;
}

.embla__slide {
  position: relative;
  flex: 0 0 12%;
  margin-right: 0.5rem;
}

.embla__slide__img {
  height: 200px;
  width: auto;
  object-fit: cover;
}

/* About Section */
#about {
  padding: 40px 20px;
  background-color: var(--background);
}

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
}

.about-photo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 25%;
  margin-bottom: 20px;
}

.bio-container {
  flex: 2;
  text-align: center;
}

.bio-container h1 {
  margin: 0;
  font-size: 2rem;
  color: var(--text);
}

.bio-container h3 {
  font-size: 1.5rem;
  color: var(--primary);
}

.bio-container p {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text);
}

.bio-container a {
  color: var(--accent);
  text-decoration: none;
}

.bio-container a:hover {
  text-decoration: underline;
}

/* Projects Section */
#projects {
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Project Card Styling */
.project-card {
  width: 300px;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--background);
  color: var(--text);
  border: 1px solid var(--primary);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  text-align: left;
}

.project-card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.project-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

/* Project Content */
.project-content {
  padding: 15px;
}

.project-content h3 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--text);
}

.project-content p {
  font-size: 1rem;
  color: var(--text);
}

/* Footer */
.footer {
  background-color: var(--background);
  color: var(--text);
  padding: 20px;
  width: calc(100% - 40px);
  position: relative;
  bottom: 0;
}

.footer-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
}

.footer-content p {
  margin: 0;
  font-size: 1rem;
}

/* Blog & Braindump */
#blog {
  padding: 20px;
  background-color: var(--background);
  color: var(--text);
  max-width: 1000px;
  text-align: center;
  margin: 0 auto;
}

#blog h1 {
  font-size: 2rem;
  margin-bottom: 40px;
}

#blog .content-section {
  margin-bottom: 40px;
}

#blog .content-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

#blog .content-item {
  margin-bottom: 20px;
}

#blog .content-item a {
  font-size: 1.2rem;
  color: var(--primary);
  text-decoration: none;
  transition: color 0.3s ease;
}

#blog .content-item a:hover {
  color: var(--accent);
}

#blog .content-item p {
  font-size: 1rem;
  color: var(--text);
  margin-top: 5px;
}

#blog .thumbnail {
  border-radius: 8px;
  transition: transform 0.3s;
}

#blog .thumbnail:hover {
  transform: scale(1.05);
}

#blog p {
  font-size: 1rem;
  color: var(--text);
  margin-top: 20px;
}

.blog-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.blog-card {
  background-color: var(--background);
  color: var(--text);
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 20px;
  max-width: 600px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.blog-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.blog-card p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.read-more {
  font-size: 1rem;
  color: var(--accent);
  text-decoration: none;
  transition: color 0.3s ease;
}

.read-more:hover {
  color: var(--primary);
}

.blog-card:hover {
  transform: scale(1.02);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack navbar elements vertically */
    width: calc(100% - 60px);
    justify-content: center;
  }

  .nav-links {
    flex-direction: row; /* Keep links in a row */
    gap: 5px;
    justify-content: flex-start;
    margin-left: 0;
    margin-right: 0;
  }

  .social-media-icons {
    gap: 10px;
  }

  .social-media-icons a {
    font-size: 20px;
  }

  .content {
    margin-top: 80px;
    padding: 0px;
  }

  .embla__slide__img {
    height: 100px;
  }

  .projects-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .project-card {
    margin-bottom: 20px;
  }

  .photo {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 5%;
    margin-bottom: 20px;
  }
}
